import React, { useState, useRef } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import InputMask from "react-input-mask"

const Modal = ({ id, title, name, forwardRef, isOpen, isResults, transactionID, product_code }) => {

    const env = process.env;
    const phoneRef = useRef()

    return (
        <div id={isResults ? 'resultsModal' : null} ref={forwardRef} className={isOpen ? 'modal open-modal' : 'modal'}>
            <div className='modal-content'>

                <Formik
                    initialValues={{ nombre: '', apellido: '', email: '', telefono: '', recibirinfo: false, recibirinfoemail: false, aceptotermcon: false }}
                    validate={values => {
                        const errors = {};

                        if (!values.nombre) {
                            errors.nombre = 'Campo requerido';
                        }
                        if (!values.apellido) {
                            errors.apellido = 'Campo requerido';
                        }
                        if (!values.telefono) {
                            errors.telefono = 'Campo requerido';
                        } else if (!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i.test(values.telefono)) {
                            errors.telefono = 'Formato inválido';
                        }

                        if (!values.email) {
                            errors.email = 'Campo requerido';
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = 'Formato inválido';
                        }

                        // Validación del checkbox recibirinfo
                        if (!values.recibirinfo) {
                            errors.recibirinfo = 'Campo requerido';
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        const resultsModal = document.querySelector('#resultsModal')
                        const activeModal = document.querySelector('.open-modal')
                        const activeForm = activeModal.querySelector('form')
                        const formData = new FormData(activeForm)
                        const ManejoPrestamo = activeForm.getAttribute('name')
                        const phoneOptedinCheck = values.recibirinfo ? "opted-in_phone" : "no_phone"
                        formData.append('transactionID', transactionID)
                        formData.append('product_code', product_code)
                        formData.append('manejoprestamo', ManejoPrestamo)
                        setSubmitting(true);

                        async function updateData() {
                            const updateReq = await axios.post(env.REACT_APP_UPDATE_ENDPOINT, formData)
                            const updateRes = await updateReq;

                            if (ManejoPrestamo === "Solicitar Prueba Manejo") {
                                //Push GTM
                                window.dataLayer.push({
                                    'event': 'formSubmitted',
                                    'formName': 'Test_Drive_onCalculator',
                                    'phoneOptedIn': phoneOptedinCheck,
                                });
                                //Push GTM
                            } else if (ManejoPrestamo === "Solicitar Estimado") {
                                //Push GTM
                                window.dataLayer.push({
                                    'event': 'formSubmitted',
                                    'formName': 'Estimate_onCalculator',
                                    'phoneOptedIn': phoneOptedinCheck,
                                });
                                //Push GTM
                            }

                            // close current modal
                            document.querySelector('.open-modal').classList.remove('open-modal')
                            resultsModal.classList.add('open-modal')
                            resultsModal.querySelector('#results-text').innerHTML = updateRes.data.message
                            setSubmitting(false);
                        }
                        updateData()

                        setTimeout(() => {
                            resultsModal.classList.remove('open-modal')
                        }, 5000)
                    }}
                >

                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form id={id} name={name} onSubmit={handleSubmit} className='w-full'>
                            <div className='modal-header'>
                                <h6>{title}</h6>
                                <button
                                    className='modal-close'
                                    type='button'
                                    onClick={() => { forwardRef.current.classList.remove('open-modal') }} >
                                    <img src='./images/close.svg' alt='close' className='lg:scale-75' />
                                </button>
                            </div>

                            {!isResults &&
                                <div className='modal-body'>
                                    <div className='modal-wrapper'>
                                        <div className={'col-span-12 lg:col-span-6 field provider'}>
                                            <input
                                                id={'nombre'}
                                                name={'nombre'}
                                                type={'text'}
                                                className={errors.nombre && touched.nombre ? 'input-error' : 'input'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.nombre} />
                                            <label htmlFor="nombre">Nombre</label>
                                            {errors.nombre && touched.nombre && <small>* {errors.nombre}</small>}
                                        </div>

                                        <div className={'col-span-12 lg:col-span-6 field provider'}>
                                            <input
                                                id={'apellido'}
                                                name={'apellido'}
                                                type={'text'}
                                                className={errors.apellido && touched.apellido ? 'input-error' : 'input'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.apellido} />
                                            <label htmlFor="apellido">Apellidos</label>
                                            {errors.apellido && touched.apellido && <small>* {errors.apellido}</small>}
                                        </div>

                                        <div className={'col-span-12 lg:col-span-6 field provider'}>
                                            <InputMask
                                                id={'telefono'}
                                                name={'telefono'}
                                                type={'tel'}
                                                mask="999-999-9999"
                                                className={errors.telefono && touched.telefono ? 'input-error' : 'input'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.telefono} />
                                            <label htmlFor="telefono">Celular</label>
                                            {errors.telefono && touched.telefono && <small>* {errors.telefono}</small>}
                                        </div>

                                        <div className={'col-span-12 lg:col-span-6 field provider'}>
                                            <input
                                                id={'email'}
                                                name={'email'}
                                                type={'email'}
                                                className={errors.email && touched.email ? 'input-error' : 'input'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email} />
                                            <label htmlFor="email">Correo Electrónico</label>
                                            {errors.email && touched.email && <small>* {errors.email}</small>}
                                        </div>

                                        <label className='col-span-12 provider'>
                                            <input
                                                type='checkbox'
                                                name='recibirinfo'
                                                id='recibirinfo'
                                                checked={values.recibirinfo}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <p>Acepto recibir información al número de teléfono provisto, vía llamadas, llamadas grabadas o de voz artificial y/o mensaje de texto, sobre productos y servicios de parte de Nissan de Puerto Rico, su distribuidora Motorambar, Inc., y/o sus concesionarios autorizados.</p>
                                            {errors.recibirinfo && touched.recibirinfo && <div className='field'><small>* {errors.recibirinfo}</small></div>}
                                        </label>

                                        <label className='col-span-12 provider'>
                                            <input
                                                type='checkbox'
                                                name='recibirinfoemail'
                                                id='recibirinfoemail'
                                                checked={values.recibirinfoemail}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <p>Suscribirme al servicio de correo electrónico sobre productos y servicios de Nissan.</p>
                                        </label>

                                        <label className='col-span-12 provider'>
                                            <input
                                                type='checkbox'
                                                name='aceptotermcon'
                                                id='aceptotermcon'
                                                checked={values.aceptotermcon}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <p>Acepto los <a href='https://es.nissan.pr/terminos.html' target='_blank' rel="noreferrer">Términos y Condiciones</a></p>
                                        </label>
                                    </div>
                                </div>
                            }

                            {!isResults &&
                                <div className='modal-footer'>
                                    <button type='submit' className='col-span-12 lg:col-span-6 btn single' disabled={isSubmitting}>
                                        Enviar información
                                        <img src="./images/arrow.svg" alt="arrow" />
                                    </button>
                                </div>
                            }
                        </form>
                    )}

                </Formik>
            </div>
        </div>
    )
}

export default Modal
