import React from 'react'
import NumberFormat from 'react-number-format';

const Vehicles = ({ vehicle, trim }) => {

  const model = vehicle?.name.toLowerCase()

  return (

    <div className="vehicle-data">
      <img src={`./images/vehicles/${model}/${trim?.image}`} alt={trim?.name} className='w-full aspect-auto' />
      <h2>
        {vehicle?.name}<span>®</span>
        <small>{vehicle?.subtitle}</small>
      </h2>
      <div className='mt-4'>
        <h3>
          <small>Desde</small>
          <NumberFormat
            value={trim?.price}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'} />
        </h3>
        <p className='text-black/50'>MPG hasta {trim?.mpg}</p>
      </div>
    </div>
  )
}

export default Vehicles
