import React from 'react'
import NumberFormat from 'react-number-format';

const Input = ({ type, label, name, className, value, percentage, ammount }) => {

    if (ammount) {
        return (
            <div className={ className }>
                <NumberFormat id={ name } name={ name } value={ ammount } displayType={'input'} thousandSeparator={ true } prefix={'$'} />
                <label htmlFor="">{ label }</label>
            </div>
        )
    } else if (percentage) {        
        return (
            <div className={ className }>
                <NumberFormat id={ name } name={ name } value={ percentage } displayType={'input'} thousandSeparator={ true } suffix={'%'} />
                <label htmlFor="">{ label }</label>
            </div>
        )
    } else {
        return (
            <div className={ type === "hidden" ? 'hidden provider' : className }>
                <input type={ type } id={ name } name={ name } value={ value } className="input" />
                <label htmlFor="">{ label }</label>
            </div>
        )
    }


}

export default Input
