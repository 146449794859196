import React from 'react'

const Select = ({ className, name, label, placeholder, children, handler, forwardRef }) => {
    return (
        <div className={ className }>
            <select id={ name } name={ name } ref={ forwardRef } placeholder={ placeholder } onChange={ handler }>
                {children}
            </select>
            <label htmlFor={ name }>{ label }</label>
        </div>
    )
}

export default Select
