import React from 'react'

const Button = ({ type, className, label, id, handler, hasImage, isSecondary }) => {
  return (
        <button type={ type } id={ id } className={ isSecondary ? 'btn alt' : 'btn' } onClick={ handler }>
            <span>{ label }</span>
            { hasImage && <img src="/images/arrow.svg" alt="arrow" /> }
        </button>
  )
}

export default Button
