import { useState, useEffect, useRef } from 'react'
import Vehicles from './components/Vehicles'
import Select from './components/Select'
import Input from './components/Input'
import Button from './components/Button'
import Modal from './components/Modal'
import spinner from './images/spinner.gif'
import axios from 'axios'

//Init app
function App() {

  const env = process.env;

  const [loading, setLoading] = useState(true)
  const [vehicles, setVehicles] = useState([])
  const [activeVehicle, setActiveVehicle] = useState([])
  const [newVehicle, setNewVehicle] = useState([])
  const [trims, setTrims] = useState([])
  const [activeTrim, setActiveTrim] = useState([])
  const [nextSteps, setNextSteps] = useState(false)
  const [percentage, setPercentage] = useState('2.99')
  const [downPayment, setDownpayment] = useState('0.00')
  const [estimatedPayments, setEstimatedPayment] = useState('0.00')
  const [transactionID, setTransactionID] = useState(0)
  const [product_code, setProductCode] = useState(0)
  const paymentOptions = [36, 48, 60, 72, 84]

  const vehicleRef = useRef(24)
  const testdriveModalRef = useRef()
  const estimatesModalRef = useRef()
  const resultsModalRef = useRef()


  // Fetch Vehicles and Trims
  useEffect(() => {

    async function fetchData() {

      axios.post(env.REACT_APP_CAR_MODELS_ENDPOINT)
        .then(res => res.data)
        .then(data => {

          const cars = data.Models.filter(car => {
            return car.CalculadoraForm === 1
          })

          setVehicles(cars)
          setActiveVehicle(cars[0])
          setLoading(false)

        }).catch(err => console.log(err))

      axios.post(env.REACT_APP_TRIMS_ENDPOINT + vehicles[0]?.id)
        .then(res => res.data)
        .then(data => {

          const trims = data.Trims.filter(trim => {
            return trim.CalculadoraForm === 1
          })

          setTrims(trims)
          setActiveTrim(trims[0])

        }).catch(err => console.log(err))

    } fetchData()

  }, [loading])


  // Set selected vehicles and trims
  useEffect(() => {
    if (newVehicle != null) {
      async function fetchTrims() {
        axios.post(env.REACT_APP_TRIMS_ENDPOINT + newVehicle.id)
          .then(res => res.data)
          .then(data => {

            const trims = data.Trims.filter(trim => {
              return trim.CalculadoraForm === 1
            })

            setActiveVehicle(vehicles[vehicleRef.current.value])
            setTrims(trims)
            setActiveTrim(trims[0])

          })
      } fetchTrims()
    }
  }, [newVehicle])


  // Calculation and next steps
  const calculate = e => {

    e.preventDefault()

    const terms = document.querySelector('#plazoprestamo').value
    const apr = document.querySelector('#interes').value.replace('%', '') / 1200
    const ammount = activeTrim.price.replace(',', '').replace('.00', '')
    const pronto = document.querySelector('#pronto').value.replace('$', '').replace(',', '')
    const total = ammount - pronto
    const finalPayment = (total * apr / (1 - (Math.pow(1 / (1 + apr), terms)))).toFixed(2)

    document.querySelector('#pagomensual').value = "$" + finalPayment

    setDownpayment(pronto)
    setPercentage(document.querySelector('#interes').value)
    setEstimatedPayment(`$${finalPayment}`)
  }

  // Form submit
  const formHandle = async (e) => {

    e.preventDefault();

    const form = e.currentTarget
    const fields = form.querySelectorAll('.provider')
    let formData = new FormData();

    fields.forEach(field => {

      const input = field.firstChild

      switch (input.id) {
        case "modelo":
          formData.append('modelo', input[input.selectedIndex].text);



          break

        case "grado":
          formData.append('grado', input[input.selectedIndex].text)
          break

        default:
          formData.append(input.id, input.value)
          break;
      }

    })

    if (form.id === "calculadora") {

      const req = await axios.post(env.REACT_APP_POST_ENDPOINT, formData)
      const res = await req;

      //Push GTM
      window.dataLayer.push({
        'event': 'formSubmitted',
        'formName': 'Calculator',
        'model': formData.get("modelo"),
        'grado': formData.get("grado"),
        'plazos': formData.get("plazoprestamo")
      });
      //Push GTM

      if (res.data.nextSteps) {
        setNextSteps(true)
        setTransactionID(res.data.transactionID)
        setProductCode(res.data.product_code)
      }
    }
  }

  // Modals
  const openModal = e => {

    const testdriveModal = testdriveModalRef.current
    const estimatesModal = estimatesModalRef.current

    e.currentTarget.id === 'testdrive-trigger'
      ? testdriveModal.classList.add('open-modal')
      : estimatesModal.classList.add('open-modal')

  }


  if (loading) {
    return (
      <div className='flex flex-row items-center justify-center w-screen h-screen'>
        <img src={spinner} alt='loading' />
      </div>
    )
  }

  return (
    <main className='App'>
      <section className='app-container'>


        <Vehicles vehicle={activeVehicle} trim={activeTrim} />


        <form id="calculadora" className='calculator' onSubmit={e => { calculate(e); formHandle(e); }}>

          {/* Adding */}
          <Input type="hidden" value={activeVehicle?.product_code} name="product_code" id="product_code" />

          <Select label='Modelo' name='modelo' placeholder='Escoge un modelo' className='z-50 col-span-12 md:col-span-6 field provider' forwardRef={vehicleRef} handler={e => { setNewVehicle(vehicles[e.currentTarget.value]) }}>
            {vehicles.map((vehicle, i) => <option key={vehicle.id} value={i} >{vehicle.name + ' ' + vehicle.year}</option>)}
          </Select>

          <Select label='Grado' name='grado' placeholder='Escoge el grado' className='z-50 col-span-12 md:col-span-6 field provider' handler={(e) => { setActiveTrim(trims[e.currentTarget.value]) }}>
            {trims.map((trim, key) => <option key={trim.id} value={key}>{trim.name}</option>)}
          </Select>

          <Input type="text" label='Tasa de Interés (APR)' name='interes' percentage={percentage} className='col-span-12 md:col-span-4 field provider' />

          <Select label='Plazo del préstamo' name='plazoprestamo' placeholder='Escoge la cantidad de plazos a pagar' className='z-50 col-span-12 md:col-span-4 field provider' handler={(e) => { console.log(e.currentTarget.value) }}>
            {paymentOptions.map(payment => <option key={payment} value={payment}>{`${payment} meses`}</option>)}
          </Select>

          <Input type="text" label='Pronto' name='pronto' ammount={downPayment} className='col-span-12 md:col-span-4 field provider' />
          <Input type="text" label='Cantidad a financiar' name='cantidadfinanciar' ammount={activeTrim?.price} className='col-span-12 md:col-span-6 field provider' />
          <Input type="text" label='Pago mensual estimado' ammount={estimatedPayments} name='pagomensual' className='col-span-12 md:col-span-6 field provider' />

          <div className='disclaimer'>
            <p>[*] La Calculadora de Pagos sólo provee un estimado del pago mensual y no contempla cargos adicionales por concepto de seguro, tablilla, ACAA, licencia y otros cargos. El pago mensual variará de acuerdo a la tasa de interés aprobada, el modelo y nivel de equipamiento seleccionado, el monto a financiar y otros cargos aplicables. La tasa de interés (APR) podrá variar de acuerdo al historial de crédito del cliente y a la negociación particular del cliente con la institución financiera correspondiente. La Calculadora de Pagos provee el precio sugerido de venta (MSRP, por sus siglas en inglés) correspondiente al modelo (“trim level”) básico de cada unidad; modelos con un nivel de equipamiento mayor, conllevan un precio sugerido de venta mayor. El precio de venta final lo establece el concesionario. Todos los precios sugeridos de venta aplican únicamente para el territorio de Puerto Rico y pueden cambiar sin previo aviso. Para más información y ofertas disponibles, consulte a su concesionario Nissan autorizado más cercano. El precio ilustrado no incluye accesorios.</p>
          </div>

          <div className="flex flex-col items-center justify-start w-full col-span-12 gap-6 md:flex-row">
            <Button type="submit" label={'Calcular'} hasImage={true} />
            {nextSteps &&
              <>
                <Button type="button" id='testdrive-trigger' label={'Solicitar prueba de manejo'} isSecondary handler={openModal} />
                <Button type="button" id='estimates-trigger' label={'Solicitar estimado'} isSecondary handler={openModal} />
              </>
            }
          </div>

        </form>


      </section>

      <Modal id={'testdrive'} name={'Solicitar Prueba Manejo'} forwardRef={testdriveModalRef} title={'Prueba de Manejo'} transactionID={transactionID} product_code={product_code} />
      <Modal id={'estimates'} name={'Solicitar Estimado'} forwardRef={estimatesModalRef} title={'Solicitud de Estimados'} transactionID={transactionID} product_code={product_code} />
      <Modal id={'results'} forwardRef={resultsModalRef} title={'Gracias por su tiempo'} isResults />

    </main>
  )

}

export default App;
